import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { SectionWrapper } from "../components/sections";
import { ArrowRight } from "../components/icon/arrow-left";
import { cn } from "../lib/helpers";

const REDIRECT_DELAY = 1000;

export const query = graphql`
  query RedirectTemplateQuery($id: String!) {
    doc: sanityRedirect(id: { eq: $id }) {
      _type
      id
      _id
      slug {
        current
      }
      url
    }
  }
`;

const RedirectTemplate = ({ data }) => {
  const isBrowser = typeof window !== "undefined";
  const isExternal = data?.doc?.url.match(/https?:\/\/[^/]+/);
  const path = isExternal ? data?.doc.url : `/${data?.doc.url}/`;

  useEffect(() => {
    if (isBrowser && data?.doc.url) {
      setTimeout(() => {
        window.location.replace(path);
      }, REDIRECT_DELAY);
    }
  }, []);

  return (
    <LayoutContainer doc={{ ...data?.doc }}>
      <PageHeadMeta title="Redirect" doc={data?.doc} />
      <SectionWrapper layout="centered" container="large">
        <div className="text-center my-16">
          <a
            href={path}
            className="group hover:bg-dark-2 p-16 rounded-2xl block"
          >
            <span className={isExternal ? "block" : "mr-1"}>
              Redirecting you to
            </span>
            <span
              className={cn(
                isExternal ? "text-sm" : "font-semibold",
                "text-link group-hover:text-black"
              )}
            >
              {data?.doc.url} {isExternal ? null : <ArrowRight />}
            </span>
          </a>
        </div>
      </SectionWrapper>
    </LayoutContainer>
  );
};

export default RedirectTemplate;
